











































import { Component, Vue } from "vue-property-decorator";
import { FundManageFeeFeeRecordDetailDto } from "@/api/appService";
import api from "@/api";
import ExportWord from "@/components/DownloadWord/index.vue";
@Component({
  name: "FundManageFeeRecordDetail",
  components: {
    ExportWord,
  },
})
export default class FundManageFeeRecordDetail extends Vue {
  detail: FundManageFeeFeeRecordDetailDto = {};
  styles = "";
  selector = "table-detail-world";
  filename = "管理费详情";

  created() {
    if (this.$route.params.id) {
      this.fetchDetail();
    }
  }

  // 获取详情
  async fetchDetail() {
    api.fundManageFeeRecord
      .get({ id: Number(this.$route.params.id) })
      .then((res) => {
        this.detail = { ...res };
      });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
